/* istanbul ignore file */

const env = {};
// eslint-disable-next-line no-useless-escape
const locationRegexp = /^(?:(?:([\w\-]+)-dot-)?([\w\-]+)(?:\.front\.|\.))?([\w\-]+\.[\w\-]+)$/;
const match = window.location.hostname.match(locationRegexp) || [];
const [DOMAIN, NAME, BRANCH_NAME] = match.reverse();
const isRelease = () => BRANCH_NAME.indexOf('release') !== -1;

env.CITIBOX_API_PROTOCOL = 'https:';
if (DOMAIN) {
  if (BRANCH_NAME && !isRelease()) {
    env.CITIBOX_API_HOST = `${BRANCH_NAME}.api.${DOMAIN}`;
    env.CITIBOX_AUTH_URL = `https://${BRANCH_NAME}-dot-accounts.front.${DOMAIN}`;
  } else {
    env.CITIBOX_API_HOST = `api.${DOMAIN}`;
    env.CITIBOX_AUTH_URL = `https://accounts.${DOMAIN}`;
  }
}
env.DOMAIN = DOMAIN;
env.NAME = NAME;
env.BRANCH_NAME = BRANCH_NAME;
env.NODE_ENV = DOMAIN && DOMAIN.match(/citibox.(com|app)/) ? 'production' : 'development';

env.CITIBOX_CLIENT_ID = '8ER630ijcsRNBlDWLYIdw28b3tod0Rk7vaavO6Y8';
env.GOOGLE_ANALYTICS_PROPERTY_ID = 'UA-121440426-4';
env.APP_NAME = 'deployers';
env.APP_VERSION = '3.2.1';
window.process = { env };
export { env };