import {
	RESET_PROSPECTION,
	SET_PROSPECTION,
	SET_PROSPECTION_ADDRESSES,
	UPDATE_PROSPECTION,
	SET_PROSPECTION_INTERCOM_PICTURE,
	SET_PROSPECTION_CONTRACT,
	ADD_CONTRACT_FAILED,
	GET_PROSPECTION_CONTRACT_TEMPLATE,
	GET_PROSPECTION_CONTRACT_TEMPLATE_SUCCESS,
	GET_PROSPECTION_CONTRACT_TEMPLATE_FAILED,
	PROSPECTION_UPDATE_CONTACT_PERSON,
	PROSPECTION_DELETE_CONTACT_PERSON,
	UPDATE_DISMISS_REASON,
} from '../actions/prospection';
import { createSelector } from 'reselect';

const getInitialState = () => {
	return {
		current: getInitialLocation(),
		dismissReason: null,
		dismissCustomReason: null
	};
};

const getInitialLocation = () => {
	return {
		contract: [],
		intercom_picture: [],
		board_status: 'none',
		has_doorman: false,
		contact_people: [],
		minimum_dimensions: {
			height: 0,
			width: 0,
			depth: 0,
		},
		entrances: []
	};
};

const prospection = (state = getInitialState(), action) => {
	switch(action.type) {
	case UPDATE_DISMISS_REASON:
		return {
			...state,
			dismissReason: action.dismissReason,
			customDismissReason: action.customDismissReason
		};

	case RESET_PROSPECTION:
		return {
			current: getInitialLocation()
		};

	case SET_PROSPECTION:
	case UPDATE_PROSPECTION:
	case PROSPECTION_UPDATE_CONTACT_PERSON:
	case PROSPECTION_DELETE_CONTACT_PERSON:
	case GET_PROSPECTION_CONTRACT_TEMPLATE:
	case GET_PROSPECTION_CONTRACT_TEMPLATE_SUCCESS:
	case GET_PROSPECTION_CONTRACT_TEMPLATE_FAILED:
	case SET_PROSPECTION_INTERCOM_PICTURE:
	case SET_PROSPECTION_ADDRESSES:
	case SET_PROSPECTION_CONTRACT:
	case ADD_CONTRACT_FAILED:
		return {
			...state,
			current: currentProspection(state.current, action)
		};

	default:
		return state;
	}
};

const currentProspection = function(current = getInitialLocation(), action) {
	switch(action.type) {
	case SET_PROSPECTION:
	case UPDATE_PROSPECTION:
		current.placement_pictures = current.placement_pictures || [];
		if (action.current.placement_pictures) {
			current.placement_pictures = [
				...current.placement_pictures,
				...action.current.placement_pictures
			];
		}
		if (action.current.board_status === 'none' || action.current.board_status === 'not_needed') {
			action.current.board_date = null;
		}
		return {
			...current,
			...action.current,
		};
	case SET_PROSPECTION_CONTRACT:
		return {
			...current,
			contract: action.contract,
			contractError: false
		};
	case SET_PROSPECTION_ADDRESSES:
		current.entrances = current.entrances || [];
		if (action.entrances) {
			current.entrances = [
				...action.entrances
			];
		}
		return {
			...current
		};
	case SET_PROSPECTION_INTERCOM_PICTURE:
		current.intercom_picture = action.intercom_picture || [];
		return {
			...current
		};
	case PROSPECTION_UPDATE_CONTACT_PERSON:
	case PROSPECTION_DELETE_CONTACT_PERSON:
		return {
			...current,
			contact_people: contactPeople(current.contact_people, action)
		};

	case GET_PROSPECTION_CONTRACT_TEMPLATE:
		return {
			...current,
			contractTemplateError: false
		};

	case GET_PROSPECTION_CONTRACT_TEMPLATE_SUCCESS:
		return {
			...current,
			contractTemplate: action.contractTemplate,
			contractTemplateError: false
		};

	case GET_PROSPECTION_CONTRACT_TEMPLATE_FAILED:
		return {
			...current,
			contractTemplate: '',
			contractTemplateError: true
		};

	case ADD_CONTRACT_FAILED:
		return {
			...current,
			contract: '',
			contractError: true
		};

	default:
		return current;
	}
};

const findPersonIndex = (people, person) => {
	let index = -1;
	for(let i = 0, key = person.id ? 'id' : 'type'; index === -1 && i < people.length; i++) {
		if (people[i][key] === person[key]) {
			index = i;
		}
	}
	return index;
};

const contactPeople = function(contact_people = [], action) {
	switch(action.type) {
	case PROSPECTION_DELETE_CONTACT_PERSON: {
		const index = findPersonIndex(contact_people, action.contactPerson);
		if (index !== -1) {
			contact_people[index] = {
				id: contact_people[index].id,
				type: contact_people[index].type,
				address: '',
				full_name: '',
				locality: '',
				official_identification: '',
				phone_number: '',
				postal_code: '',
				province: ''
			};
		}
		return [...contact_people];
	}
	case PROSPECTION_UPDATE_CONTACT_PERSON: {
		const index = findPersonIndex(contact_people, action.contactPerson);
		if (index === -1) {
			contact_people.push(action.contactPerson);
		} else {
			contact_people[index] = {
				...contact_people[index],
				...action.contactPerson
			};
		}
		return [...contact_people];
	}
	default:
		return [...contact_people];
	}

};

export default prospection;

const findItemByKey = (arr, key, value) => arr && arr.find((item) => item[key] === value);
const sumPropertyValues = (arr, key) => arr.reduce((acc, current) => current[key] ? acc + current[key] : acc, 0);

const locationSaleSelector = state => state.prospection.current;
const contactPeopleSelector = state => locationSaleSelector(state).contact_people;
const entrancesSelector = state => locationSaleSelector(state).entrances;

export const residentialUnitsSelector = createSelector(
	entrancesSelector,
	(entrances) => sumPropertyValues(entrances, 'residential_units')
);

export const countrySelector = createSelector(
	entrancesSelector,
	(entrances) => entrances && entrances.length && entrances[0].country? entrances[0].country : ''
);

export const buildingTypeSelector = createSelector(
	locationSaleSelector,
	(locationSale) => locationSale.building_type
);

export const householdsNumberSelector = createSelector(
	locationSaleSelector,
	residentialUnitsSelector,
	(locationSale, residentialUnits) => isNaN(locationSale.households_number) ? residentialUnits : locationSale.households_number
);

export const hasDoormanSelector = createSelector(
	locationSaleSelector,
	(locationSale) => locationSale.has_doorman
);

export const doormanSelector = createSelector(
	contactPeopleSelector,
	(people) => findItemByKey(people, 'type', 'doorman')
);

export const boxNumberSelector = createSelector(
	locationSaleSelector,
	(locationSale) => locationSale.box_number || null
);

export const boxModelSelector = createSelector(
	locationSaleSelector,
	(locationSale) => locationSale.box_model || null
);

export const modulesNumberSelector = createSelector(
	locationSaleSelector,
	(locationSale) => locationSale.modules_number || null
);