import { doRequest, getRequestUrl, getQueryParams, getRequestHeaders, getRequestBody, queryObject} from './utils/request.js';

const urlMethod = 'location-sales';

const getUrl = ({id, addressId, limit = 50}) => {
	let url;
	if (id) {
		url = getRequestUrl(urlMethod, id);
	} else {
		url = getRequestUrl(urlMethod, '');
		url += getQueryParams({
			limit: limit,
			address_id: addressId
		});
	}
	return url;
};

const getHeaders = () => {
	return getRequestHeaders();
};

const getBody = (body) => {
	return getRequestBody(body);
};

const getData = ({id, addressId}, method, body) => {
	const url = getUrl({id, addressId});
	return {
		url: url,
		method: method,
		headers: getHeaders(),
		body: body ? getBody(body) : null,
		preParse: (response) => _preParse(url, response)
	};
};


const getContactData = ({id, contact_person_id}, method, body) => {
	let url = getUrl({id}) + '/contact-people' + (contact_person_id ? `/${contact_person_id}`: '');
	const { CITIBOX_API_HOST } = window.process.env;
	const MOCKS = (CITIBOX_API_HOST === 'citibox.mock');
	if (MOCKS) {
		url = url.replace('.json/', '-') + '.json';
	}
	return {
		url: url,
		method: method,
		headers: getHeaders(),
		body: body ? getBody(body) : null,
		preParse: (response) => _preParse(url, response)
	};
};

const getDismissReasonData = ({id}, method, body) => {
	const url = getUrl({id}) + '/lost';
	return {
		url: url,
		method: method,
		headers: getHeaders(),
		body: body ? getBody(body) : null,
		preParse: (response) => _preParse(url, response)
	};
};

const getEntrancesData = ({id}, method) => {
	const { CITIBOX_API_HOST } = window.process.env;
	const MOCKS = (CITIBOX_API_HOST === 'citibox.mock');
	let url = getUrl({id});
	if (MOCKS) {
		url = url.replace('.json', '') + '-entrances.json';
	} else {
		url = getUrl({id}) + '/entrances' + getQueryParams({
			limit: 50
		});
	}
	return {
		url: url,
		method: method,
		headers: getHeaders(),
		preParse: (response) => _preParse(url, response)
	};
};

const checkErrors = (response) => {
	if (response && response.code) {
		return Promise.reject(response);
	}
	return response;
};

const findByAddress = (results, address_id) => {
	return results.filter(
		(location) => location.entrances.filter(
			(entrance) => entrance.address_id === address_id
		).length > 0
	);
};

const _preParse = (url, response) => {
	var search = new URL(url).search;
	const { address_id } = queryObject(search);
	if (address_id) {
		response.results = findByAddress(response.results, address_id);
		response.count = response.results.length;
	}
	return response;
};

export const getLocationSaleByAddress = (addressId) => {
	const method = 'GET';
	const { url, headers, body, preParse} = getData({addressId}, method);
	return doRequest(url, method, headers, body, preParse)
		.then(checkErrors)
		.then(response => {
			response.results = response.results.length ? response.results[0] : {};
			return response;
		});
};

export const getLocationSale = (id) => {
	const method = 'GET';
	const { url, headers, body, preParse} = getData({id}, method);
	return doRequest(url, method, headers, body, preParse)
		.then(checkErrors);
};

export const putLocationSale = (locationSale) => {
	const method = 'PUT';
	const { id } = locationSale;
	if (id) {
		const locationSaleBody = { ...locationSale };
		delete locationSaleBody.intercom_picture;
		delete locationSaleBody.contract;
        if(locationSaleBody.box_model && locationSaleBody.box_model !== 'BOX2A') {
            locationSaleBody.modules_number = 0;
        }
		const { url, headers, body, preParse} = getData({id}, method, locationSaleBody);
		return doRequest(url, method, headers, body, preParse)
			.then(checkErrors);
	} else {
		return Promise.resolve();
	}
};

export const putLocationSaleContact = async (prospectionId, contact) => {
	const method = 'PUT';
	if (prospectionId) {
		const { url, headers, body, preParse} = getContactData({
			id: prospectionId,
			contact_person_id: contact.id
		}, method, contact);
		return doRequest(url, method, headers, body, preParse)
			.then(checkErrors);
	} else {
		return Promise.resolve();
	}
};

export const postLocationSalesContact = async (prospectionId, contact) => {
	const method = 'POST';
	if (prospectionId && contact.full_name && contact.phone_number) {
		const { url, headers, body, preParse} = getContactData({
			id: prospectionId
		}, method, contact);
		return doRequest(url, method, headers, body, preParse)
			.then(checkErrors);
	} else {
		return Promise.resolve();
	}
};

export const postDismissReason = (prospectionId, reason, customReason) => {
	const method = 'POST';
	if (prospectionId) {
		const { url, headers, body, preParse} = getDismissReasonData(
			{ id: prospectionId },
			method,
			{ reason, custom_reason: customReason }
		);
		return doRequest(url, method, headers, body, preParse)
			.then(checkErrors);
	} else {
		return Promise.resolve();
	}
};

export const getEntrances = (prospectionId) => {
	const method = 'GET';
	if (prospectionId) {
		const { url, headers, body, preParse} = getEntrancesData(
			{ id: prospectionId },
			method
		);
		return doRequest(url, method, headers, body, preParse)
			.then(checkErrors);
	} else {
		return Promise.resolve();
	}
};
